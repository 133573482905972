import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../utils/axios';
import useAuthRedirect from '../utils/authRedirect';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser } from '../redux/features/auth/authSlice';
import { UserAnalyticsBlock } from '../components/users/UserAnalyticsBlock';
import Moment from 'react-moment';
import { banUser } from '../redux/features/users/usersSlice';

export const UserPage = () => {
  useAuthRedirect(null, '/login');

  const { currentUser, isLoading } = useSelector(state => state.auth);
  const [user, setUser] = useState(null);
  const [analytics, setAnalytics] = useState([]);
  const [levelMappings, setLevelMappings] = useState({});
  const params = useParams();
  const dispatch = useDispatch();
  const [chapter, setChapter] = useState('chapter_showroom');

  const fetchUser = useCallback(async () => {
    const { data } = await axios.get(`/user/${params.id}`);
    setUser(data.user);
  }, [params.id]);

  /*
  const fetchAnalytics = useCallback(async (chapter) => {
    try {
      const { data } = await axios.get(`/analytics/${params.id}?chapterName=${chapter}`);
      setAnalytics(data.data.levels);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setAnalytics([]);
      } else {
        console.error("Ошибка при загрузке аналитики:", error);
      }
    }
  }, [params.id]);
  */

  const fetchAnalytics = useCallback(async (chapterName) => {
    try {
      console.log("Fetching analytics for chapter:", chapterName);

      const { data } = await axios.get(`/analytics/${params.id}?chapterName=${chapterName}`);
      setAnalytics(data.data.levels);

      const mappingData = await axios.get(`/chapter-json?chapterName=${chapterName}`);
      console.log(mappingData.data);

      setLevelMappings(mappingData.data);
    } catch (error) {
      console.error("Ошибка при загрузке аналитики или JSON:", error);
      setAnalytics([]);
    }
  }, [params.id]);

  const handleBan = async () => {
    try {
      if (isLoading || !user) {
        return;
      }

      const result = await dispatch(banUser({ userID: user.id }));

      if (banUser.fulfilled.match(result)) {
        await fetchUser();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleRoleChange = async (newRoleID) => {
    try {
      if (isLoading || !user) return;

      const { data } = await axios.post('/user_role', {
        userID: user.id,
        roleID: newRoleID,
      });

      console.log(data.message);
      await fetchUser(); 
    } catch (err) {
      console.error("Ошибка при изменении роли:", err);
    }
  };

  useEffect(() => {
    dispatch(getCurrentUser());
  }, [dispatch]);

  useEffect(() => {
    if (!isLoading && currentUser) {
      fetchUser();
      fetchAnalytics('chapter_showroom');
    }
  }, [isLoading, currentUser, fetchUser, fetchAnalytics]);

  useEffect(() => {
    if (!isLoading && currentUser) {
      fetchAnalytics(chapter);
    }
  }, [isLoading, chapter, fetchAnalytics]); 

  const handleChapterChange = (newChapter) => {
    setChapter(newChapter);
  };

  const navigate = useNavigate();
  const handleQuit = () => {
    navigate('/users');
  };

  if (isLoading || !user) {
    return <div>Loading...</div>;
  }

  const canBlock = currentUser.roleID >= 20 && user.id !== currentUser.id;
  const canAssignRole = currentUser.roleID >= 20 && user.id !== currentUser.id;

  return (
    <div className="p-8 bg-gray-100 h-screen">
      <div className="flex justify-end mb-4">
        <button
          onClick={handleQuit}
          className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400 transition duration-200"
        >
          Назад
        </button>
      </div>
      <div className="bg-white p-6 rounded-lg shadow-md">
        <div className="mb-6">
          <div className="flex justify-between items-center">
            <h2 className="text-3xl font-bold">{user.name} {user.isBanned && (
              <span> (Заблокирован) </span>
            )}</h2>
            {canBlock && user.isBanned == false && (
              <button
                onClick={handleBan}
                className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition duration-200"
              >
                Заблокировать
              </button>
            )}
          </div>
          <div className="text-gray-600 mt-2">
            {user?.createdAt && (
              <p>Дата регистрации: <Moment date={user?.createdAt} format="D MMM YYYY" /></p>
            )}
            <p>Email: {user.email}</p>
          </div>

          <div className="mt-6 flex space-x-4">
            {canAssignRole && user.roleID !== 20 && (
              <button
                onClick={() => handleRoleChange(20)}
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-200"
              >
                Назначить модератором
              </button>
            )}
            {canAssignRole && user.roleID === 20 && (
              <button
                onClick={() => handleRoleChange(0)}
                className="bg-yellow-500 text-white px-4 py-2 rounded-md hover:bg-yellow-600 transition duration-200"
              >
                Снять модератора
              </button>
            )}
          </div>
          </div>

          <hr className="my-4 border-gray-100 border-2" />

          <div className="mt-3">
            <button
              onClick={() => handleChapterChange('chapter_showroom')}
              className="outline outline-2 outline-gray-300 text-gray-600 px-4 py-2 rounded-md hover:bg-gray-100 transition duration-200 mr-2"
            >
              Шоу Рум
            </button>
            <button
              onClick={() => handleChapterChange('chapter_pass')}
              className="outline outline-2 outline-gray-300 text-gray-600 px-4 py-2 rounded-md hover:bg-gray-100 transition duration-200"
            >
              Пропускная система
            </button>
          </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
          {analytics.length > 0 ? (
            analytics.map((level, index) => (
              <UserAnalyticsBlock chapterName={chapter} key={index} data={level} levelMappings={levelMappings} />
            ))
          ) : (
            <div className="col-span-1 sm:col-span-2 text-center text-gray-600">
              У пользователя пока нет данных по этому прохождению
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
