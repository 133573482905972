import React, { useState, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser, logout } from '../redux/features/auth/authSlice';
import axios from '../utils/axios';
import useAuthRedirect from '../utils/authRedirect';

export const NavigationMenu = () => {
  const { currentUser, isLoading } = useSelector((state) => state.auth);

  useAuthRedirect(null, '/login');

  const dispatch = useDispatch();

  const logoutHandler = () => {
    dispatch(logout());
    window.localStorage.removeItem('token');
    window.location.reload(true);
  }
  
  useEffect(() => {
    dispatch(getCurrentUser());
  }, [dispatch]);

  if (!currentUser || isLoading) {
    return <div>Loading...</div>;
  }

  const roleName = currentUser.roleID >= 2 ? 'Админ' : 'Пользователь';

  return (
    <div className='w-1/5 h-screen bg-white text-black p-5 flex flex-col justify-between'>
      <div>
        <div className='mb-8'>
          <Link to="/" className='hover:scale-105'>
            <img src="/assets/logo.png" alt="Comitas" className='w-full h-auto' />
          </Link>
        </div>

        <div className='mb-8 text-center'>
          <h2 className=" text-lg font-semibold">Добро пожаловать, {currentUser.name}!</h2>
          <p className="text-sm">Ваша роль: {roleName}</p>

          <div className="mt-4 flex justify-center space-x-4">
            <Link
              to={`/user/${currentUser.id}`}
              className='text-blue-500 hover:text-blue-600 underline'
            >
              Мой профиль
            </Link>
            <a
              href="#logout"
              onClick={logoutHandler}
              className='text-red-500 hover:text-red-600 underline cursor-pointer'
            >
              Выйти
            </a>
          </div>
        </div>

        <ul>
          <li><Link to="/users" className='block py-2 px-4 bg-red-500 hover:bg-black text-white'>Список пользователей</Link></li>
        </ul>
      </div>
    </div>
  );
};
