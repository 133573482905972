import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUsersList } from "../redux/features/users/usersSlice";
import { UsersListItem } from "../components/users/UsersListItem";
import useAuthRedirect from "../utils/authRedirect";
import { useNavigate } from "react-router-dom";

export const UsersListPage = () => {
  useAuthRedirect(null, "/login");

  const [searchTerm, setSearchTerm] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    users = [],
    currentPage = 1,
    totalPages = 1,
    error,
    isLoading,
  } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(
      getUsersList({
        page: currentPage,
        limit: 15,
        search: searchTerm,
      })
    );
  }, [dispatch, currentPage, searchTerm, error, navigate]);

  const handleSearch = () => {
    dispatch(
      getUsersList({
        page: 1,
        limit: 15,
        search: searchTerm,
      })
    );
  };

  const handleClear = () => {
    setSearchTerm("");
    dispatch(getUsersList({ page: 1, limit: 15 }));
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      dispatch(
        getUsersList({
          page: currentPage - 1,
          limit: 15,
          search: searchTerm,
        })
      );
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      dispatch(
        getUsersList({
          page: currentPage + 1,
          limit: 15,
          search: searchTerm,
        })
      );
    }
  };

  return (
    <div className="p-8 bg-gray-100 min-h-screen">
      {/* Поиск */}
      <div className="flex items-center justify-start mb-4">
        <input
          type="text"
          placeholder="Поиск"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-1/3 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
        />
        <button
          onClick={handleClear}
          className="ml-2 bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition duration-200"
        >
          Очистить
        </button>
      </div>

      {/* Таблица */}
      <div className="bg-white shadow-lg rounded-lg overflow-auto max-h-[70vh]">
        <table className="table-auto w-full">
          <thead className="sticky top-0 bg-gray-500">
            <tr>
              <th className="px-4 py-2 text-white">№</th>
              <th className="px-4 py-2 text-white">Имя</th>
              <th className="px-4 py-2 text-white">Email</th>
              <th className="px-4 py-2 text-white">Статус</th>
              <th className="px-4 py-2 text-white">Статистика</th>
            </tr>
          </thead>
          <tbody>
            {isLoading && (
              <tr>
              <td>Loading</td>
              <td>Loading</td>
              <td>Loading</td>
              <td>Loading</td>
              <td>Loading</td>
              </tr>
            )}

            {isLoading === false && users &&
              users.map((user) => (
                <UsersListItem key={user.id} params={user}></UsersListItem>
              ))}
          </tbody>
        </table>
      </div>

      {/* Навигация */}
      {isLoading === false && (
        <div className="flex justify-start items-center mt-4">
          <button
            onClick={handlePreviousPage}
            className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400 transition duration-200"
            disabled={currentPage === 1}
          >
            Назад
          </button>
          <span className="mx-2 text-gray-700">
            {currentPage}/{totalPages}
          </span>
          <button
            onClick={handleNextPage}
            className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400 transition duration-200"
            disabled={currentPage === totalPages}
          >
            Вперед
          </button>
      </div>
    )}
      
    </div>
  );
};
