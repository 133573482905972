import React from 'react'

export const UserAnalyticsBlock = ({ chapterName, data, levelMappings }) => {
  var targetLevelName = data.level;

  if (levelMappings.data !== undefined) {
    targetLevelName = levelMappings.data.find((targetLevel) => {
      return targetLevel && data.level.trim() === targetLevel.key.trim();
    })?.value;
  }

  return (
    <div className="bg-gray-100 p-4 rounded-lg shadow-md">
      <h3 className="text-lg font-semibold text-gray-700 mb-2 bg-gray-200 p-2 rounded-lg">
        {targetLevelName}
      </h3>
      <p>Прохождений: <span className="float-right">{data.allCompletesCount}</span></p>
      <p>Попыток прохождения: <span className="float-right">{data.completesTriesCount}</span></p>
      <p>Очков при первом прохождении: <span className="float-right">{data.firstCompleteScore == null ? 0 : data.firstCompleteScore}</span></p>
      <p>Очков при последнем прохождении: <span className="float-right">{data.lastCompleteScore == null ? 0 : data.lastCompleteScore}</span></p>
    </div>
  );
}
