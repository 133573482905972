import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { checkAuth } from '../redux/features/auth/authSlice';
import { toast } from 'react-toastify';

export const MainPage = () => {
  const { status } = useSelector(state => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isAuth = useSelector(checkAuth);

  console.log("isAuth", isAuth);

  useEffect(() => {
    if (!isAuth) {
      navigate('/login');
    } else {
      navigate('/users');
    }
  
    if (status) {
      toast(status); 
    }
  }, [status, isAuth, navigate]);

  return (
	<div className='w-full'>
  </div>
  )
}
