import React from 'react'
import { Link } from 'react-router-dom'

export const UsersListItem = ({ params }) => {
  return (
	<tr key={params.id} className="border-b">
	<td className="border px-4 py-2">{params.id}</td>
	<td className="border px-4 py-2">{params.name} {params.roleID >= 20 ? (<sup>(Модератор)</sup>) : (<span></span>)}</td>
	<td className="border px-4 py-2">{params.email}</td>
	<td className="border px-4 py-2">
		<span
		className={`font-semibold ${
			params.isBanned === false ? 'text-green-600' : 'text-red-600'
		}`}
		>
		{
			params.isBanned === false ? 'Активен' : 'Заблокирован'
		}
		</span>
	</td>
	<td className="border px-4 py-2">
		<Link
		to={`/user/${params.id}`}
		className="text-indigo-600 hover:text-indigo-800"
		>
		Просмотр профиля
		</Link>
	</td>
	</tr>
  )
}
