import { Layout } from "./components/Layout";
import { Routes, Route } from 'react-router-dom';
import { MainPage } from './pages/MainPage';
import { LoginPage } from "./pages/LoginPage";
import { RestorePasswordPage } from "./pages/RestorePasswordPage";
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UsersListPage } from "./pages/UsersListPage";
import { UserPage } from "./pages/UserPage";

function App() {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="restore_password" element={<RestorePasswordPage />} />
        <Route path="users" element={<UsersListPage />} />
        <Route path="user/:id" element={<UserPage />} />
      </Routes>

      <ToastContainer position="bottom-right"/>

    </Layout>
  );
}

export default App;