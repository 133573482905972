import React from 'react';
import { useLocation } from 'react-router-dom';
import { NavigationMenu } from './NavigationMenu';

export const Layout = ({ children }) => {
  const location = useLocation();
  const noMenuRoutes = ['/register', '/login', '/restore_password'];
  const showMenu = !noMenuRoutes.includes(location.pathname);

  return (
    <React.Fragment>
      <div className={`${showMenu ? 'flex' : 'block'} min-h-screen h-screen`}>
        {showMenu && <NavigationMenu />}
        <div className={`${showMenu ? 'w-4/5' : 'w-full'} flex-grow`}>
          {children}
        </div>
      </div>
    </React.Fragment>
  );
};