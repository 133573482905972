import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { checkAuth } from '../redux/features/auth/authSlice';

const useAuthRedirect = (redirectIfAuth = null, redirectIfNotAuth = '/login') => {
  const { status } = useSelector(state => state.auth);
  const isAuth = useSelector(checkAuth);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuth && redirectIfNotAuth) {
      navigate(redirectIfNotAuth);
    } else if (isAuth && redirectIfAuth) {
      navigate(redirectIfAuth);
    }
  }, [isAuth, redirectIfAuth, redirectIfNotAuth, navigate]);

  useEffect(() => {
    if (status === 'Unauthorized' || status === 'Error') {
      window.localStorage.removeItem('token');
      if (!isAuth) {
        navigate('/login');
      }
    }
  }, [status, isAuth, navigate]);
};


export default useAuthRedirect;