import axios from 'axios';

// https://comitasremote.dpill.ru/api
// http://127.0.0.1:3002/api
// https://api.comitas.ru

const instance = axios.create({
	baseURL: 'https://api.comitas.ru/api',
});

instance.interceptors.request.use(config => {
	config.headers.authorization = window.localStorage.getItem('token');

	return config;
});

export default instance;