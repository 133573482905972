import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../utils/axios';

const initialState = {
	targetUser: null,
	users: [],
	totalPages: 1,
	currentPage: 1,
	loading: false,
	error: null
};

export const getUsersList = createAsyncThunk('users/getUsersList', async (params, {rejectWithValue}) => {
	try {
		const { data } = await axios({
			method: 'get',
			url: '/users',
			headers: {}, 
			params: {
			  page: params.page.toString(),
			  limit: params.limit.toString(),
			  search: params.search
			}
		  });

		return data;
	} catch (error) {
		if (error.response && error.response.status === 401) {
			return rejectWithValue({ message: 'Unauthorized' });
		}
		return rejectWithValue(error.response?.data || { message: 'Something went wrong' });
	}
});

export const banUser = createAsyncThunk('users/banUser', async (params, {rejectWithValue}) => {
	try {
		const { data } = await axios({
			method: 'post',
			url: '/ban_user',
			headers: {}, 
			data: {
			  userID: params.userID
			}
		  });

		return data;
	} catch (error) {
		if (error.response && error.response.status === 401) {
			return rejectWithValue({ message: 'Unauthorized' });
		}
		return rejectWithValue(error.response?.data || { message: 'Something went wrong' });
	}
});

export const usersSlice = createSlice({
	name: 'users',
	initialState: initialState,
	reducers: {

	},
	extraReducers: (builder) => {
		builder
		// get users list
			.addCase(getUsersList.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(getUsersList.fulfilled, (state, action) => {
				state.isLoading = false;
				state.users = action.payload?.users;
				state.currentPage = action.payload?.currentPage;
				state.totalPages = action.payload?.totalPages;
			})
			.addCase(getUsersList.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.payload?.message || 'Failed to fetch users';
			})
		// ban user
			.addCase(banUser.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(banUser.fulfilled, (state, action) => {
				state.isLoading = false;
			})
			.addCase(banUser.rejected, (state, action) => {
				state.isLoading = false;
			})
	}
});

export default usersSlice.reducer;