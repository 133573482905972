import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from '../utils/axios';

export const RestorePasswordPage = () => {
  const [userEmail, setEmail] = useState('');
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const handleSubmit = async () => {
    try {
      const response = await axios.post('/reset_password', { email: userEmail });

      setSuccess(response.data.message);
      setError(null);
    } catch (err) {
      setSuccess(null);
      if (err.response) {
        setError(err.response.data.message || 'Произошла ошибка. Попробуйте позже.');
      } else {
        setError('Ошибка на стороне клиента. Проверьте подключение.');
      }
    }
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="bg-white p-6 rounded-lg shadow-lg w-1/4">
        <div className="mb-8 text-center">
          <a href="/" className="hover:scale-105">
            <img src="/assets/logo.png" alt="Comitas" className="mx-auto h-fit w-auto" />
          </a>
        </div>

        <form onSubmit={(e) => e.preventDefault()}>
          {error && (
            <div className="mb-4 p-3 text-red-700 bg-red-100 border border-red-500 rounded-md">
              {error}
            </div>
          )}

          {success && (
            <div className="mb-4 p-3 text-green-700 bg-green-100 border border-green-500 rounded-md">
              {success}
            </div>
          )}

          <div className="mb-4">
            <label htmlFor="email" className="block text-gray-700 text-sm font-semibold mb-2">
              Введите Email, к которому привязан аккаунт
            </label>

            <input
              type="email"
              id="email"
              name="email"
              value={userEmail}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Введите email"
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
              required
            />
          </div>

          <div className="text-center">
            <button
              type="submit"
              onClick={handleSubmit}
              className="bg-indigo-500 text-white py-2 px-4 rounded-md w-full hover:bg-indigo-600 transition duration-200"
            >
              Восстановить пароль
            </button>
          </div>
        </form>

        <div className="mt-2 text-center">
          <Link to="/login" className="text-gray-500 hover:text-gray-700">
            Вернуться к авторизации
          </Link>
        </div>
      </div>
    </div>
  );
};
