import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useAuthRedirect from '../utils/authRedirect';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../redux/features/auth/authSlice';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

export const LoginPage = () => {
  useAuthRedirect('/');

  const [userEmail, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const { status } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      const result = await dispatch(loginUser({ email: userEmail, password }));

      if (loginUser.fulfilled.match(result)) {
        navigate('/');
      } else {
        const errorData = result.payload;

        if (errorData) {
          const { message, status, errID } = errorData;

          switch (status) {
            case 404:
              setError(message || 'Пользователь не найден');
              break;
            case 403:
              if (errID === 1) {
                setError('Произошла ошибка. Свяжитесь с модератором.');
              } else if (errID === 2) {
                setError('Недостаточно прав для входа.');
              } else {
                setError(message || 'Доступ запрещён.');
              }
              break;
            case 400:
              setError(message || 'Проверьте правильность данных.');
              break;
            default:
              setError(message || 'Произошла ошибка.');
          }
        }
      }
    } catch (err) {
      console.error(err);
      setError('Ошибка на стороне клиента. Попробуйте позже.');
    }
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="bg-white p-6 rounded-lg shadow-lg w-1/4">
        <div className="mb-8 text-center">
          <a href="/" className="hover:scale-105">
            <img src="/assets/logo.png" alt="Comitas" className="mx-auto h-fit w-auto" />
          </a>
        </div>

        <form onSubmit={(e) => e.preventDefault()}>
          {error && (
            <div className="mb-4 p-3 text-red-700 bg-red-100 border border-red-500 rounded-md">
              {error}
            </div>
          )}

          <div className="mb-4">
            <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={userEmail}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Введите email"
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
              required
            />
          </div>

          <div className="mb-6">
            <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">
              Пароль
            </label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Введите пароль"
                className="password-input w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                required
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
              >
                {showPassword ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
              </button>
            </div>
          </div>

          <div className="text-center">
            <button
              type="submit"
              onClick={handleSubmit}
              className="bg-indigo-500 text-white py-2 px-4 rounded-md w-full hover:bg-indigo-600 transition duration-200"
            >
              Войти
            </button>
          </div>
        </form>

        <div className="mt-2 text-center">
          <Link to="/restore_password" className="text-gray-500 hover:text-gray-700">
            Забыли пароль?
          </Link>
        </div>
      </div>
    </div>
  );
};