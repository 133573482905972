import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../utils/axios';

const initialState = {
	currentUser: null,
	token: window.localStorage.getItem('token') || null,
	isLoading: false,
	//dashboardAvailable: false,
	status: null
};

export const registerUser = createAsyncThunk('auth/registerUser', async ({ username, email }, { rejectWithValue }) => {
	try {
		const { data } = await axios.post('/register', {
			name: username,
			email: email
		});

		if (data.token) {
			window.localStorage.setItem('token', data.token);
		}

		return data;
	} catch (err) {
		return rejectWithValue(err.response.data);
	}
});

export const loginUser = createAsyncThunk('auth/loginUser', async ({ email, password }, { rejectWithValue }) => {
	try {
		const { data } = await axios.post('/auth', { email, password, dashboard: true });

		if (data.token) {
			window.localStorage.setItem('token', data.token);
		}

		return data;
	} catch (err) {
		return rejectWithValue({
			status: err.response?.status,
			message: err.response?.data?.message,
			errID: err.response?.data?.errID,
		});
	}
});

export const getCurrentUser = createAsyncThunk('auth/currentUser', async (_, { rejectWithValue }) => {
    try {
        const token = window.localStorage.getItem('token');
        const { data } = await axios.get('/current_user', {
            headers: {
                authorization: `${token}`
            },
        });
        return data;
    } catch (err) {
        if (err.response?.status === 418) {
            window.localStorage.removeItem('token'); // Удаляем токен
        }
        return rejectWithValue(err.response?.data || { message: 'Неизвестная ошибка' });
    }
});


export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		logout: (state) => {
			state = {
				currentUser: null,
				token: null,
				isLoading: false,
				status: null
			};
		}
	},
	extraReducers: (builder) => {
		builder
			// регистрация
			// запрос обрабатывается
			.addCase(registerUser.pending, (state) => {
				state.isLoading = true;
				state.status = null;
			})
			// запрос выполнен
			.addCase(registerUser.fulfilled, (state, action) => {
				state.isLoading = false;
				state.status = action.payload.message;
				state.currentUser = action.payload.user;
				state.token = action.payload.token;

				if (action.payload.password) {
					console.log("Debug password: " + action.payload.password);
				}
			})
			// запрос отклонен
			.addCase(registerUser.rejected, (state, action) => {
				state.status = action.payload?.message || 'Error';
				state.isLoading = false;
			})

			//логин
			// запрос обрабатывается
			.addCase(loginUser.pending, (state) => {
				state.isLoading = true;
				state.status = null;
			})
			// запрос выполнен
			.addCase(loginUser.fulfilled, (state, action) => {
				state.isLoading = false;
				state.status = action.payload.message;
				state.currentUser = action.payload.user;
				state.token = action.payload.token;
			})
			// запрос отклонен
			.addCase(loginUser.rejected, (state, action) => {
				state.isLoading = false;
				state.status = action.payload?.message || 'Неизвестная ошибка';
				state.currentUser = null;
				state.token = null;
			})

			// проверка авторизации
			.addCase(getCurrentUser.pending, (state) => {
				state.isLoading = true;
				state.status = null;
			})
			// запрос выполнен
			.addCase(getCurrentUser.fulfilled, (state, action) => {
				state.isLoading = false;
				state.status = null;
				state.currentUser = action.payload?.user;
				state.token = action.payload?.token;
			})
			// запрос отклонен
			.addCase(getCurrentUser.rejected, (state, action) => {
				state.status = action.payload?.message || 'Unauthorized';
				state.isLoading = false;
			
				if (action.payload?.message === 'Unauthorized') {
					state.currentUser = null;
					state.token = null;
				}
			})
			
	}
});

//export const checkAuth = state => Boolean((state.auth.token || window.localStorage.getItem('token')) && state.dashboardAvailable);
export const checkAuth = state => Boolean((state.auth.token || window.localStorage.getItem('token')));

export const { logout } = authSlice.actions;
export default authSlice.reducer;
